import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://94cb3db78005665f45d2335d8559c0a3@us.sentry.io/4506688033587200",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});